import axios, { AxiosInstance, AxiosResponse, CancelToken } from 'axios';
import { getApiConfig } from '../../../app/services/environment';
import dayjs from 'dayjs';

const apiConfig = getApiConfig();

const axiosInstance: AxiosInstance = axios.create({
  baseURL: apiConfig.apiBaseUrl,
});

export interface DetailedResponse {
    status: string;
}

export interface PodConfigurationModel {
    id: number,
    storeNumber: string,
    storeName: string,
    starbucksStoreNumber: string,
    itemNumber: string,
    itemDescription: string,
    franchiseName: string,
    maxOrderQuantity: number | string | null,
    safetyStock: number | string | null,
    isParEnabled: boolean,
    configurationLevel: number,
    enabled: boolean
}

export interface PredictedDemandModel {
  itemNumber: string,
  predictionInfo: PredictionInfo | null,
  predictedDemandItems: PredictedDemandItemModel[]
}

export interface PredictedDemandItemModel {
  quantity: number,
  date: Date,
  hasPrediction: boolean,
  predictedDemandQuantity: number
}

export interface PredictionInfo {
  safetyStock: number | null,
  maxOrderQuantity: number | null
}

export interface StoreConfiguration {
  isPodEnabled: boolean
}

class Client {

  private static instance: Client;
  private readonly axiosInstance: AxiosInstance;

  private constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  public static getInstance(): Client {
    if (!Client.instance) {
      Client.instance = new Client(axiosInstance);
    }
    return Client.instance;
  }


  public async getConfigurations(configurationLevel: number, franchiseName?: string, storeNumber?: string, cancelToken?: CancelToken): Promise<PodConfigurationModel[]> {
    try {
      const params = {
        configurationLevel,
        franchiseName,
        storeNumber,
      };

      const response: AxiosResponse<PodConfigurationModel[]> = await this.axiosInstance.get('getconfigurations', { params, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  }

  public async getStoreConfiguration(franchiseName: string, storeNumber: string, cancelToken?: CancelToken): Promise<StoreConfiguration> {
    try {
      const params = {
        franchiseName,
        storeNumber,
      };

      const response: AxiosResponse<StoreConfiguration> = await this.axiosInstance.get('getstoreconfiguration', { params, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  }

  public async getPredictions(franchiseName: string, storeNumber: string, availableDates: string[], cancelToken?: CancelToken): Promise<PredictedDemandModel[]> {
    try {
      const dates = availableDates.map(x => {
        const parsedDate = dayjs(x, 'DD-MM-YYYY');
        return parsedDate.format('YYYY-MM-DD');
      }).join(',');
      const params = {
        franchiseName,
        storeNumber,
        dates,
      };

      const response: AxiosResponse<PredictedDemandModel[]> = await this.axiosInstance.get('getpredictions', { params, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  }

  public async getPredictionsForItems(franchiseName: string, storeNumber: string, deliveryDates: string[], itemNumbersList: string[], cancelToken?: CancelToken): Promise<PredictedDemandModel[]> {
    try {
      const dates = deliveryDates.map(x => {
        const parsedDate = dayjs(x, 'DD-MM-YYYY');
        return parsedDate.format('YYYY-MM-DD');
      }).join(',');
      const itemNumbers = itemNumbersList.join(',');
      const params = {
        franchiseName,
        storeNumber,
        dates,
        itemNumbers,
      };

      const response: AxiosResponse<PredictedDemandModel[]> = await this.axiosInstance.get('getpredictionsforitems', { params, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  }

  public async create(model: PodConfigurationModel, cancelToken?: CancelToken): Promise<DetailedResponse> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post('createconfiguration', model, { cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  }

  public async update(model: PodConfigurationModel, cancelToken?: CancelToken): Promise<DetailedResponse> {
    try {
      const response: AxiosResponse = await this.axiosInstance.put('updateconfiguration', model, { cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  }
  

  public async delete(id: number, cancelToken?: CancelToken): Promise<DetailedResponse> {
    try {
      const response: AxiosResponse = await this.axiosInstance.delete(`deleteconfiguration/${id}`, { cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  }
}

export const podClient = Client.getInstance();