import { Button, Grid, Typography,  Dialog,  DialogContent, DialogActions, Box } from '@mui/material';
import { MagicWand01, X } from  '../../../../assets';
import { theme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import { getFormattedDate } from './../../../../utils';


export interface PodInfoProps  {
  open: boolean;
  onClose: () => void;
}
const PodConfigurationInfo = ( props: PodInfoProps) => {
  const { t } = useTranslation('orderForm');

  const getNextMondayDate = (): string => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const daysUntilNextMonday = (8 - dayOfWeek) % 7 || 7;
    today.setDate(today.getDate() + daysUntilNextMonday);
    return getFormattedDate(today);
  };
  
  return (
    <Dialog open={props.open}
      onClose={props.onClose}
      maxWidth='sm'
      fullWidth>
      <Grid 
        sx={{ m:'2px', pt: '10px' }}>
        <Grid 
          container
        >
          <Grid item
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold' }}
            xs={11}>
            <MagicWand01 style={{ color:theme.palette.info[200], marginRight: '5px', marginBottom: '5px' }} />
            <Typography variant='textLG'> {t('podConfigInfoTitle')}</Typography>
          </Grid>
          <Grid item
            sx={{ display: 'flex', justifyContent: 'right', pr: '14px' }}
            xs={1}>
            <X  onClick={props.onClose} />
          </Grid>
        </Grid>
      </Grid>
      <DialogContent>
        <Box 
          sx={{ borderTop:'1px solid ', borderBottom:'1px solid ', borderColor:theme.palette.custom.gray[300], pt:'20px', pb:'20px' }}>
          <Typography
            variant='textSM'>{t('podConfigInfoBody', { date: getNextMondayDate() })}</Typography>
        </Box>
        
      </DialogContent>
      <DialogActions sx={{ pr: '20px', pb: '15px' }}>
        <Button
          size='md'
          variant="secondary"
          onClick={props.onClose}>{t('confirm')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PodConfigurationInfo;