import { Button, Checkbox, FormControl, InputAdornment, SvgIcon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { Store } from './../../../app/services/api/generated';
import CustomPagination from './../../../components/forms/Pagination';
import { ConfigurationLevel } from '../enums/ConfigurationLevel';
import { PodConfigurationModel } from './../../../app/services/api/podClient';
import { ChangeEvent, useEffect, useState } from 'react';
import { mergeArrays } from '../helpers/ArrayHelper';
import { useTranslation } from 'react-i18next';
import { SearchSm } from './../../../assets';

type IProps = {
    franchiseName: string,
    stores: Store[],
    existingStoreConfigurations: PodConfigurationModel[],
    // eslint-disable-next-line no-unused-vars
    handleSave: (config: PodConfigurationModel) => void;
}

const StoreConfigurationsList = (props: IProps) => {
  const { t } = useTranslation('managePodConfigurations');
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const [search, setSearch] = useState<string>('');
  const [availableConfigs, setAvailableConfigs] = useState<PodConfigurationModel[]>([]);
  const [allConfigurations, setAllConfigurations] = useState<PodConfigurationModel[]>([]);
  const [selectedConfig, setSelectedConfig] = useState<PodConfigurationModel | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  useEffect(() => {
    prepareConfigurations();
    setPage(0);
  }, [props.stores]);

  useEffect(() => {
    const configs = mergeArrays(availableConfigs, props.existingStoreConfigurations);
    setAllConfigurations(configs ?? []);
  }, [availableConfigs, props.existingStoreConfigurations]);

  const prepareConfigurations = () => {
    setAvailableConfigs(getAvailableConfigsFromStores());
  };

  const getAvailableConfigsFromStores = () => {
    return props.stores.map((x) => {
      const config: PodConfigurationModel = {
        id: 0,
        franchiseName: props.franchiseName ?? '',
        storeNumber: x.storeNumber ?? '',
        storeName: x.storeName ?? '',
        starbucksStoreNumber: x.starbucksStoreNumber ?? '',
        itemNumber: '',
        itemDescription: '',
        configurationLevel: ConfigurationLevel.Franchise,
        maxOrderQuantity: null,
        safetyStock: null,
        isParEnabled: false,
        enabled: false,
      };
      return config;
    });
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, checked } = event.target;

    let hasIndexChanged = false;
    const updatedItems = [...allConfigurations];
    const rows = visibleRows();

    const currentIndex = getConfigurationIndex(rows[selectedIndex]);
    const newIndex = getConfigurationIndex(rows[index]);

    if (selectedConfig && selectedIndex !== index) {
      updatedItems[currentIndex] = { ...selectedConfig };
      hasIndexChanged = true;
    }

    if (hasIndexChanged || !selectedConfig) {
      setSelectedConfig(allConfigurations[newIndex]);
    }

    updatedItems[newIndex] = { 
      ...updatedItems[newIndex], 
      [name]: checked,
    };

    setAllConfigurations(updatedItems);
    setSelectedIndex(index);
  };

  const handleCancel = (index: number) => {
    if (!selectedConfig) {
      return;
    }

    const rows = visibleRows();
    const actualIndex = getConfigurationIndex(rows[index]);

    const updatedItems = [...allConfigurations];
    updatedItems[actualIndex] = { ...selectedConfig };

    setAllConfigurations(updatedItems);
    setSelectedConfig(null);
    setSelectedIndex(-1);
  };

  const getConfigurationIndex = (config: PodConfigurationModel) => {
    return config ? allConfigurations.findIndex(x => x.storeNumber === config.storeNumber) : -1;
  };

  const handlePageChange = (pageNumber: number) => {
    handleCancel(selectedIndex);
    setPage(pageNumber);
  };

  const maxPages = () => {
    return Math.ceil(filteredRows().length / rowsPerPage);
  };

  const filteredRows = () => {
    const lowerCaseQuery = search.toLowerCase();
    return lowerCaseQuery === '' ? 
      allConfigurations : 
      allConfigurations.filter(x => x.storeName.toLowerCase().includes(lowerCaseQuery) || x.storeNumber.toLowerCase().includes(lowerCaseQuery));
  };

  const visibleRows = () => {
    return filteredRows().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchValue = event.target.value;

    handleCancel(selectedIndex);
    setSearch(newSearchValue);
    setPage(0);
  };

  const hasPendingChanges = (config: PodConfigurationModel) => JSON.stringify(selectedConfig) !== JSON.stringify(config);
  
  return (
    <>
      <FormControl sx={{ p: '10px' }}
        fullWidth>
        <TextField placeholder={t('search')}
          size='small'
          autoComplete='off'
          id="search-input"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon><SearchSm/></SvgIcon>
              </InputAdornment>
            ),
          }}
          value={search}
          onChange={handleSearchChange}
          fullWidth
        />
      </FormControl>
      <TableContainer sx={{ overflowY:'auto' }}>
        <Table sx={{ tableLayout: 'fixed', minWidth: 700, overflow: 'hidden' }}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={3}>{t('storeName')}</TableCell>
              <TableCell>{t('storeNumber')}</TableCell>
              <TableCell>{t('predictiveOrdering')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              visibleRows().map((row, index) => (
                <TableRow
                  key={row.storeNumber}
                >
                  <TableCell colSpan={3}>
                    {row.storeName}
                  </TableCell>
                  <TableCell>
                    {row.storeNumber}
                  </TableCell>
                  <TableCell>
                    <Checkbox checked={row.enabled}
                      name='enabled'
                      onChange={($event) => handleCheckboxChange($event, index)}></Checkbox>
                  </TableCell> 
                  <TableCell sx={{ textAlign: 'center' }}>
                    {
                      selectedIndex === index && hasPendingChanges(row) &&
                        <>
                          <Button 
                            variant="primary"
                            sx={{ m: 1 }}
                            size="md"
                            onClick={() => props.handleSave(row)}>
                            {t('save')}
                          </Button>
                          <Button
                            variant="secondary"
                            sx={{ m: 1 }}
                            size="md"
                            onClick={() => handleCancel(index)}>
                            {t('cancel')}
                          </Button>
                        </>
                    }
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {
        visibleRows().length > 0 ?
          <CustomPagination
            page={page}
            setPage={handlePageChange}
            maxPages={maxPages()}
            breakpointForChangeDisplay={120000}
          ></CustomPagination>
          :
          <Typography sx={{ p: '12px' }}>{t('noData')}</Typography> 
      }
    </>
  );
};

export default StoreConfigurationsList;