import { TableRow, TableCell, Grid, Typography, TextField, Button, Dialog, DialogActions, DialogContent,
  DialogTitle, Alert, Snackbar, Box, Tooltip, useMediaQuery, IconButton } from '@mui/material';
import { AddOrderItemRequest, Item, client } from '../../../../app/services/api/orderManagementClient';
import { ChangeEvent, forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { applyRoundAndFormat } from '../../../../utils';
import useLogError from '../../../../hooks/useLogError';
import { UserContext } from '../../../../components/shared/useUser';
import { theme } from '../../../../theme';
import { isOrderCategory, OrderCategoriesTranslationMap } from '../../../../enums/OrderCategories';
import TextWithOverflowTooltip from '../../../../components/shared/TextWithOverflowTooltip';
import { useNavigate } from 'react-router-dom';
import { PredictedDemandModel } from './../../../../app/services/api/podClient';
import { MagicWand01 } from './../../../../assets';
import { QuantityWarningDialog } from './../../../../components/shared/orders/QuantityWarningDialog';
import { isAutoShipOrder } from '../../../../enums/OrderType';

type ProductRowProps = {
  product: Item;
  selectedOrderId?: number;
  basketQuantity?: number;
  onAddItemConfirmed: () => void;
  currencyCode?: string;
  showMessageWhenNoCost: boolean;
  predictedItem?: PredictedDemandModel;
  showPredictedQuantity?: boolean;
  orderType: number;
}

const ProductRow = forwardRef<{ matchPredictedQuantities: () => void } | null, ProductRowProps>(({ product, selectedOrderId, basketQuantity, currencyCode, onAddItemConfirmed, showMessageWhenNoCost, orderType, predictedItem, showPredictedQuantity }, ref) => {
  const navigate = useNavigate();
  const { t } = useTranslation('products');
  const { logError } = useLogError();
  const { selectedStore } = useContext(UserContext);
  const [quantity, setQuantity] = useState<string>(basketQuantity?.toString() ?? '0');
  const [startingQuantity, setStartingQuantity] = useState<string>(basketQuantity?.toString() ?? '0');
  const [loading, setLoading] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const predictedDemandItem = predictedItem?.predictedDemandItems?.at(0);
  const predictedQuantity = predictedDemandItem?.quantity.toString();
  const showPrediction = showPredictedQuantity && predictedDemandItem?.hasPrediction;
  const borderColor = showPrediction && predictedQuantity === quantity ? theme.palette.success[400] : '';
  const maxOrderQuantity = predictedItem?.predictionInfo?.maxOrderQuantity;
  const safetyStock = predictedItem?.predictionInfo?.safetyStock;
  const predictedDemandQuantity = predictedDemandItem?.predictedDemandQuantity;

  const [showQuantityWarningDialog, setShowQuantityWarningDialog] = useState<boolean>(false);
  const [warningMessage, setWarningMessage] = useState<string>('');
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'error' | 'warning' | 'info' | 'success';
  }>({
    open: false,
    message: '',
    severity: 'info',
  });

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(() => {
    setQuantity(basketQuantity?.toString() ?? '0');
    setStartingQuantity(basketQuantity?.toString() ?? '0');
  }, [basketQuantity]);

  const onQuantityChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.preventDefault();
    const value = Math.abs(Number(event.target.value|| '0'));
    setQuantity(value.toString());
  };

  const handleMatchQuantityChange = () => {
    setQuantity(predictedQuantity?.toString() ?? '');
  };

  useImperativeHandle(ref, () => ({
    matchPredictedQuantities,
  }));

  const matchPredictedQuantities = () => {
    if (showPrediction) {
      setQuantity(predictedQuantity ?? quantity);
    }
  };

  const onAddItemToOrder = async (itemId: number, quantity: number) => {
    if (selectedOrderId) {
      try {
        setLoading(true);
        const request: AddOrderItemRequest = {
          orderId: selectedOrderId,
          itemId,
          quantity,
          predictedQuantity: predictedDemandItem?.quantity,
        };
        const response = await client.addOrderItem(selectedStore?.storeNumber, request);
        if (response.success) {
          handleSnackbarOpen(t('addItemSuccessMessage'), 'success');
          setQuantity('');
          setStartingQuantity(quantity.toString());
          onAddItemConfirmed();
        }
        else {
          setWarningMessage(response.message);
          setShowQuantityWarningDialog(true);
          fixQuantity();
        }
      } catch (error) {
        handleSnackbarOpen(t('addItemErrorMessage') + ' ' + error, 'error');
        logError(error);
      } finally {
        setLoading(false);
        setShowDialog(false);
      }
    }
  };

  const handleSnackbarOpen = (message: string, severity: 'error' | 'warning' | 'info' | 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const hideDialogAndResetQuantity = () => {
    setShowDialog(false);
    setQuantity(startingQuantity);
  };

  const quantityError = (): string | null => {
    if ((product.maxQuantity != null) && Number(quantity) > product.maxQuantity)
      return `${t('maxQuantityAllowedIs')}: ${product.maxQuantity}`;

    if ((product.minQuantity != null) && Number(quantity) < product.minQuantity)
      return `${t('minQuantityAllowedIs')}: ${product.minQuantity}`;

    return null;
  };

  const fixQuantity = () => {
    if (Number(quantity) > product.maxQuantity)
      setQuantity(product.maxQuantity.toString());
    if (Number(quantity) < product.minQuantity)
      setQuantity(product.minQuantity.toString());
  };

  return (
    <>
      <TableRow key={product.id}>
        <TableCell sx={{ width: '60%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Grid container
            alignItems={'center'}
            spacing={1}
            onClick={() => navigate(`/store/item/${product.id}?sku=${product.sku}&orderable=true`)}
            wrap="nowrap">
            <Grid item
              xs="auto">
              <img
                style={{
                  objectFit: 'cover',
                  width: '150px',
                  height: isLargeScreen ? '75px' : '150px',
                  borderRadius: '8px',
                  border: '1px solid #ddd',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  display: 'block',
                  margin: '0',
                  padding: '0',
                }}
                alt="item-thumbnail"
                src={
                  product.thumbnailUrl
                    ? product.thumbnailUrl
                    : 'https://placehold.co/150x150'
                }
                loading="lazy"
              />
            </Grid>
            <Grid item
              sx={{ minWidth: 0, paddingLeft: '10px !important', flex: 1 }}>
              {isLargeScreen ? (
                <Grid container
                  spacing={2}>
                  <Grid item
                    xs={6}>
                    <TextWithOverflowTooltip text={product.name} />
                    <TextWithOverflowTooltip text={product.sku} />
                    <TextWithOverflowTooltip text={isOrderCategory(product.category)
                      ? t(OrderCategoriesTranslationMap[product.category as keyof typeof OrderCategoriesTranslationMap])
                      : product.category} />
                    {(showMessageWhenNoCost || product.price === 0) ?
                      <TextWithOverflowTooltip text='' />
                      :
                      <TextWithOverflowTooltip text={applyRoundAndFormat(product.price, currencyCode)} />
                    }
                  </Grid>
                  <Grid item
                    xs={6}>
                    <TextWithOverflowTooltip text={`${t('orderableUom')}: ${product.orderableUom} (${product.packSize}${product.primaryUom})`} />
                    <TextWithOverflowTooltip text={`${t('primaryUom')}: ${product.primaryUom}`} />
                    <Tooltip title={`${t('stockOnHand')}: ${product.stockOnHand}`}
                      arrow>
                      <Box style={{ whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'clip' }}>
                        {t('soh')} {product.stockOnHand}
                      </Box>
                    </Tooltip>
                    <Tooltip title={`${t('stockOnOrder')}: ${product.stockOnOrder}`}
                      arrow>
                      <Box style={{ whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'clip' }}>
                        {t('soo')}: {product.stockOnOrder}
                      </Box>
                    </Tooltip>
                  </Grid>
                </Grid>
              ) : (
                <>
                  {product.name}  <br />
                  {product.sku}   <br />
                  {isOrderCategory(product.category)
                    ? t(OrderCategoriesTranslationMap[product.category as keyof typeof OrderCategoriesTranslationMap])
                    : product.category}   <br />
                  {(showMessageWhenNoCost || product.price === 0) ?
                    ''
                    :
                    applyRoundAndFormat(product.price, currencyCode)
                  } <br />
                  {t('orderableUom')}: {product.orderableUom} ({product.packSize}{product.primaryUom})<br />
                  {t('primaryUom')}: {product.primaryUom}  <br />
                  <Tooltip title={`${t('stockOnHand')}: ${product.stockOnHand}`}
                    arrow>
                    <Box style={{ whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'clip' }}>
                      {t('soh')} {product.stockOnHand}
                    </Box>
                  </Tooltip>
                  <Tooltip title={`${t('stockOnOrder')}: ${product.stockOnOrder}`}
                    arrow>
                    <Box style={{ whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'clip' }}>
                      {t('soo')}: {product.stockOnOrder}
                    </Box>
                  </Tooltip>
                </>
              )}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Box className="product-quantity-container"
            sx={{ display: 'flex', position: 'relative', maxWidth: 100 }}>
            <TextField
              label={showPrediction ? t('predictedQuantity', { value: predictedQuantity }) : null}
              error={(quantityError()?.length ?? 0) > 0}
              helperText={quantityError()}
              size='small'
              type='number'
              autoComplete='off'
              sx={{
                maxWidth: 100,
                width: '100%',
                input: {
                  textAlign: 'center',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: borderColor,
                  },
                },

                '& .MuiInputLabel-root': {
                  color: borderColor,
                },
              }}
              disabled={!selectedOrderId || isAutoShipOrder(orderType)}
              value={quantity}
              onChange={(e) => onQuantityChange(e)}
            ></TextField> 
            {
              showPrediction &&
              <Tooltip title={
                <span>
                  {
                    predictedDemandQuantity != null && (
                      <>
                        {t('predictedDemandQuantity', { value: predictedDemandQuantity, uom: product.primaryUom })}
                        <br />
                      </>
                    )
                  }
                  {
                    maxOrderQuantity != null && (
                      <>
                        {t('maxOrderQuantity', { value: maxOrderQuantity, uom: product.primaryUom })}
                        <br />
                      </>
                    )
                  }
                  {safetyStock != null && t('safetyStock', { value: safetyStock, uom: product.primaryUom })}
                </span>
              }>
                <IconButton onClick={() => handleMatchQuantityChange()}>
                  <MagicWand01
                    color={predictedQuantity === quantity ? theme.palette.success[700] : theme.palette.custom.gray[400] }/>
                </IconButton>
              </Tooltip>
            }
          </Box>
        </TableCell>
        <TableCell>
          <Button
            disabled={!selectedOrderId || isAutoShipOrder(orderType) || (quantityError()?.length ?? 0) > 0}
            variant="primary"
            onClick={() => setShowDialog(true)}
            size="lg"
          >
            {t('updateQuantity')}
          </Button>
        </TableCell>
      </TableRow>
      <Dialog open={showDialog}
        onClose={() => setShowDialog(false)}
        maxWidth='sm'
        fullWidth>
        <DialogTitle>
          {t('confirmDialogTitle')}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item
              xs={12}>
              <Typography variant='textMD'>{t('confirmDialogContent', { item: product.name })}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions >
          <Button
            variant="secondary"
            onClick={hideDialogAndResetQuantity}>
            {t('cancel')}
          </Button>
          <Button variant="primary"
            onClick={() => onAddItemToOrder(product.id, Number(quantity))}
            disabled={loading || isAutoShipOrder(orderType)}>
            {t('update')}
          </Button>
        </DialogActions>
      </Dialog>
      <QuantityWarningDialog setShowQuantityWarningDialog={setShowQuantityWarningDialog}
        message={warningMessage}
        showDialog={showQuantityWarningDialog} />
      <Snackbar open={snackbar.open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
});

ProductRow.displayName = 'ProductRow';

export default ProductRow;