import { PodConfigurationModel } from './../../../app/services/api/podClient';

export const mergeArrays = (mainArray: PodConfigurationModel[], replacementArray: PodConfigurationModel[]) => {
  if (replacementArray.length === 0) return [...mainArray];

  return mainArray.map(item => {
    const replacementItem = replacementArray.find(x => x.franchiseName === item.franchiseName && x.storeNumber === item.storeNumber && x.itemNumber === item.itemNumber);
    if (replacementItem) {
      return {
        ...replacementItem,
        storeName: item.storeName,
        itemDescription: item.itemDescription,
      };
    }
    return item;
  });
};