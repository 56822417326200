import { Grid } from '@mui/material';
import ApplicationRolesBox from './components/ApplicationRolesBox';
import useNswagClient from '../../hooks/api/useNswagClient';
import { useEffect, useState } from 'react';
import { Persona, SuccessResponse_1OfIEnumerable_1OfPersona, SuccessResponse_1OfList_1OfKeyValuePair_2OfStringAndString, KeyValuePair_2OfStringAndString } from '../../app/services/api/generated';
import useLogError from '../../hooks/useLogError';
import PageArea from '../../components/shared/PageArea';

const ManageApplicationRoles = () => {
  const { getPersonas, getRoles } = useNswagClient();
  const [personas, setPersonas] = useState<Persona[]>([]);
  const [roles, setRoles] = useState<KeyValuePair_2OfStringAndString[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { logError } = useLogError();

  useEffect(() => {
    loadData();
    handleFetchRoles();
  }, []);

  const loadData = () => {
    setIsLoading(true);
    getPersonas().then((result: SuccessResponse_1OfIEnumerable_1OfPersona) => {
      if (result?.data) {
        setPersonas(result.data);
      }
    })
      .catch((error) => {
        logError(error);
      })
      .finally(() => (setIsLoading(false)));
  };

  const handleFetchRoles = () => {
    getRoles().then((result: SuccessResponse_1OfList_1OfKeyValuePair_2OfStringAndString) => {
      if (result?.data) {
        setRoles(result.data);
      }
    })
      .catch((error) => {
        logError(error);
      });
  };

  return <PageArea>
    <Grid container>
      <ApplicationRolesBox personas={personas}
        roles={roles}
        loadData={loadData}
        isLoading={isLoading}></ApplicationRolesBox>
    </Grid>
  </PageArea>;
};

export default ManageApplicationRoles;