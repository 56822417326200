import { Grid, Typography,  Dialog,  DialogContent, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { MagicWand01, X } from  '../../../assets';
import { theme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import CustomPagination from './../../../components/forms/Pagination';
import { Item } from './../../../app/services/api/orderManagementClient';


export interface PurchasedOrders  {
  open: boolean;
  orderType: string,
  items: Item[];
  onClose: () => void;
}
const NonPodItemsModal = ( props: PurchasedOrders) => {
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const { t } = useTranslation('orderForm');

  const maxPages = () => {
    return Math.ceil(props.items.length / rowsPerPage);
  };

  const visibleRows = () => {
    return props.items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };
  
  return (
    <Dialog open={props.open}
      onClose={props.onClose}
      maxWidth='md'
      fullWidth>
      <Grid 
        sx={{ m:'2px', pt: '10px' }}>
        <Grid 
          container
        >
          <Grid item
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold' }}
            xs={11}>
            <MagicWand01 style={{ color:theme.palette.info[200], marginRight: '5px', marginBottom: '5px' }} />
            <Typography variant='textLG'> {t('nonPodItemsTitle')}</Typography>
          </Grid>
          <Grid item
            sx={{ display: 'flex', justifyContent: 'right', pr: '14px' }}
            xs={1}>
            <X  onClick={props.onClose} />
          </Grid>
        </Grid>
      </Grid>
      <DialogContent>
        <Box 
          sx={{ borderTop:'1px solid ', borderBottom:'1px solid ', borderColor:theme.palette.custom.gray[300], pt:'20px', pb:'20px' }}>
          <Typography
            variant='textSM'>{t('nonPodItemsSubtitle', { orderType: props.orderType })}</Typography>
          <br /><br />
          <Typography
            variant='textSM'>{t('nonPodItemsSupport')}</Typography>
        </Box>
        <Grid item
          xs={12}
          p={5}>
          <TableContainer component={'div'}>
            <Table
              sx={{ tableLayout: 'fixed', overflow: 'hidden' }}
              size="small"
              aria-label="a table"
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    SKU
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    {t('name')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleRows().map((item) => (
                  <TableRow key={item.sku}>
                    <TableCell>
                      {item.sku}
                    </TableCell>
                    <TableCell>
                      {item.name}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {
            visibleRows().length > 0 ?
              <CustomPagination
                page={page}
                setPage={setPage}
                maxPages={maxPages()}
                breakpointForChangeDisplay={120000}
              ></CustomPagination>
              :
              <Typography sx={{ p: '12px' }}>{t('noData')}</Typography> 
          }
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default NonPodItemsModal;