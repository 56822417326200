import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomPagination from '../../../components/forms/Pagination';
import { theme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { Collapse, Grid, TableSortLabel } from '@mui/material';
import { KeyValuePair_2OfStringAndString, Persona, PutPersonaRequest } from '../../../app/services/api/generated';
import useNswagClient from '../../../hooks/api/useNswagClient';
import DeleteModal from './RoleDeleteModal';
import CreateEditModal from './RoleEditCreateModal';
import { PersonaPutPostRequest } from '../types/PersonaPutPostRequest';
import ExpandedRow from './ExpandedRow';
import useLogError from '../../../hooks/useLogError';

export default function DenseTable(props: {
  readonly list: Persona[], readonly loadData: () => void, readonly roles: KeyValuePair_2OfStringAndString[], readonly page: number, readonly setPage: React.Dispatch<React.SetStateAction<number>>
}) {
  const { t } = useTranslation('manageRoles');
  const { deletePersona, editPersona } = useNswagClient();
  const [data, setData] = React.useState(props.list);
  const [selectedPersona, setSelectedPersona] = React.useState<Persona | undefined>(undefined);
  const [showRoleEditModal, setShowRoleEditModal] = React.useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [expandedPersona, setExpandedPersona] = React.useState<Persona | undefined>(undefined); 
  const [order, setOrder] = React.useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Persona>('description' as keyof Persona);
  const rowsPerPage = 10;
  const maxPages = props.list
    ? Math.max(1, Math.ceil(props.list.length / rowsPerPage))
    : 1;

  const { logError } = useLogError();

  React.useEffect(() => {    
    handleSort('description' as keyof Persona);
    setData(props.list);
  }, [props.list]);

  const handleSort = (property: keyof Persona) => {
    const isAsc = orderBy === property && order === 'asc';
    if(data){
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
      handleSearchChange(property);
    }
  };

  const sortedData = (property: keyof Persona) => {
    const isAsc = order === 'asc';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const comparator = (a: any, b: any) => {
      if (property === 'personaRoles') {
        return isAsc
          ? (a.personaRoles?.length ?? 0) - (b.personaRoles?.length ?? 0)
          : (b.personaRoles?.length ?? 0) - (a.personaRoles?.length ?? 0);
      }
      
      const aValue = a[orderBy] ?? '';
      const bValue = b[orderBy] ?? '';
  
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return isAsc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return isAsc ? aValue - bValue : bValue - aValue as any;
      }
    };
    return props.list.sort(comparator);
  };
  

  const editRole = (persona: Persona | undefined) => {
    setSelectedPersona(persona);
    setShowRoleEditModal(true);
  };

  const handleCloseModal = () => {
    setShowRoleEditModal(false);
    setShowDeleteModal(false);
    setSelectedPersona(undefined);
  };

  const expandRole = (persona: Persona) => {
    if (expandedPersona && persona.id == expandedPersona.id){
      setExpandedPersona(undefined);
      return;
    }
    setExpandedPersona(persona);
  };

  const deleteRole = (persona: Persona) => {
    setSelectedPersona(persona);
    setShowDeleteModal(true);
  };

  const handleEditRole = (body: PutPersonaRequest) => {
    editPersona(selectedPersona?.id ?? 0, body).then(() => props.loadData()).catch((error) => {
      logError(error);
    });
  };

  const handleDeleteRole = () => {
    deletePersona(selectedPersona?.id ?? 0).then(() => props.loadData()).catch((error) => {
      logError(error);
    });
  };

  const handleSearchChange = (property: keyof Persona) => {
    setData(sortedData(property));
  };

  const visibleRows = () => {
    return data.slice(props.page * rowsPerPage, props.page * rowsPerPage + rowsPerPage);
  };
  
  const getTableCellStyle = (row: Persona) => {
    return {
      borderBottom: expandedPersona && expandedPersona.id === row.id ? 'none' : '1px solid',
      borderBottomColor: theme.palette.custom.gray[200],
    };
  };

  return (
    <Grid item
      xs={12}
      p={5}>
      <TableContainer component={'div'}>
        <Table
          size="small"
          aria-label="a table">
          <TableHead sx={{ paddingTop: '10px', backgroundColor: theme.palette.custom.gray[200] }}>
            <TableRow>
              <TableCell
                align="left"
                width={'40%'}>
                <TableSortLabel
                  active={orderBy === 'description'}
                  direction={order}
                  onClick={() => handleSort('description')}>
                  {t('name')}
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="left"
                width={'40%'}>
                <TableSortLabel
                  active={orderBy === 'franchiseName'}
                  direction={order}
                  onClick={() => handleSort('franchiseName')}>
                  {t('franchiseName')}
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="left"
                width={'20%'}>
                <TableSortLabel
                  active={orderBy === 'personaRoles'}
                  direction={order}
                  onClick={() => handleSort('personaRoles')}>
                  {t('pageAccessCount')}
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows().map((row) => (
              <React.Fragment key={row.id}>
                <TableRow
                  onClick={() => expandRole(row)}
                  sx={{ cursor: 'pointer' }}>
                  <TableCell
                    sx={{ 
                      ...getTableCellStyle(row),
                      textDecoration: 'underline' }}>
                    {row.description}
                  </TableCell>
                  <TableCell
                    sx={getTableCellStyle(row)}>
                    {String(row.franchiseName)}
                  </TableCell>
                  <TableCell
                    sx={getTableCellStyle(row)}>
                    {String(row.personaRoles?.length)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} 
                    colSpan={3}>
                    <Collapse in={expandedPersona && expandedPersona.id === row.id} 
                      timeout="auto" 
                      unmountOnExit>
                      <ExpandedRow entity={row}
                        delete={(persona: Persona) => deleteRole(persona)}
                        edit={(persona: Persona) => editRole(persona)}
                      />
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        page={props.page}
        setPage={props.setPage}
        maxPages={maxPages}
        breakpointForChangeDisplay={120000}
      ></CustomPagination>

      <CreateEditModal openModal={showRoleEditModal}
        onConfirm={(requestBody: PersonaPutPostRequest) => handleEditRole(requestBody)}
        persona={selectedPersona}
        roles={props.roles}
        onCancel={handleCloseModal}></CreateEditModal>
        
      <DeleteModal openModal={showDeleteModal}
        onConfirm={handleDeleteRole}
        onCancel={handleCloseModal}></DeleteModal>
    </Grid>
  );
}
